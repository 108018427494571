import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './Aegis.css';

const Aegis: React.FC = () => {
    return (
        <div className="container my-5">
            <div className="row mb-4">
                <div className="col">
                    <div className="aegis-background-image d-flex align-items-center justify-content-center rounded">
                        <h1 className="text text-start-center text text-start-white">Coming Soon...</h1>
                    </div>
                </div>
            </div>

            <hr/>

        </div>
    );
};

export default Aegis;