import React from 'react';
import Carousel from '../components/Carousel';
import Divider from '../components/Divider';
import Featurettes from '../components/Featurettes';
import TriColumn from '../components/TriColumn';

const Home: React.FC = () => {

    return (
        <div className="Home">
            <Carousel />
            <Divider />
            <TriColumn />
            <Divider />
            <Featurettes />
        </div>  
    );
};

export default Home;
