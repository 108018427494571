import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './About.css';

const About: React.FC = () => {
    return (
        <div className="container my-5">
            <div className="row mb-4">
                <div className="col">
                    <div className="about-background-image d-flex align-items-center justify-content-center rounded">
                        <h1 className="text text-start-center text text-start-white">About Us...</h1>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                <div className="col">
                    <div className="card hover-translate-y-n10 hover-shadow-lg">
                        <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#5942d2"></rect>
                            <text x="50%" y="50%" fill="#fbfbfc" dy=".3em">Micah Kirkpatrick</text>
                        </svg>
                        <div className="card-body">
                            <p className="card-text text-start">Micah is a strong AI engineering professional with a demonstrated history of working in the public safety and finance industries. 
                            As an experienced Chief Data Scientist and AI Tech Lead, he has also provided valuable services to various government sectors.   
                            Micah holds an MBA focused in Management of Technology from Georgia Institute of Technology, 
                            is a decorated Marine Corps Combat Veteran, and proud husband and father of 6. </p>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-sm btn-outline-secondary">CEO</button>
                                    <button type="button" className="btn btn-sm btn-outline-secondary">Co-Founder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card hover-translate-y-n10 hover-shadow-lg">
                        <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#5942d2"></rect>
                            <text x="50%" y="50%" fill="#fbfbfc" dy=".3em">Tommi Kirkpatrick</text>
                        </svg>
                        <div className="card-body">
                            <p className="card-text text-start">Tommi is an experienced communicator and possesses a unique talent for bringing clarity to hard-to-understand concepts. 
                            It has been said she brings the humanity to AI, serving as a ‘translator’ to the layman and thus bridging the gap between AI technologies and the end-user. 
                            She also has decades of experience in the health and wellness industries supporting those who strive to reach their full potential. 
                            Tommi is a proud wife and mother of 6.</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-sm btn-outline-secondary">CCO</button>
                                    <button type="button" className="btn btn-sm btn-outline-secondary">Co-Founder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card hover-translate-y-n10 hover-shadow-lg">
                        <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#5942d2"></rect>
                            <text x="50%" y="50%" fill="#fbfbfc" dy=".3em">Miriam Hunter</text>
                        </svg>
                        <div className="card-body">
                            <p className="card-text text-start">Miriam brings the organization and continued momentum to each and every project KirkpatrickCORE endeavors upon.  
                            As a Certified SAFe Scrum Master, she has a unique way of not only ensuring tasks are accomplished and collaborations run smoothly, 
                            but that team members have fun while doing so!  She is a natural problem-solver and networker and tackles every project head on. 
                            Miriam is a proud wife and mother of 4.</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-sm btn-outline-secondary">Scrum Master</button>
                                    <button type="button" className="btn btn-sm btn-outline-secondary">Business Development</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row mt-5">
                <div className="col-md-8 mx-auto">
                <h3 className="mb-4">at auctor urna nunc id</h3>
                <p className="text text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Platea dictumst quisque sagittis purus sit amet volutpat consequat. Aliquam malesuada bibendum arcu vitae. At volutpat diam ut venenatis tellus in metus vulputate. Senectus et netus et malesuada fames.
                </p>
                <p className="text text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Platea dictumst quisque sagittis purus sit amet volutpat consequat. Aliquam malesuada bibendum arcu vitae. At volutpat diam ut venenatis tellus in metus vulputate. Senectus et netus et malesuada fames.
                </p>
                <p className="text text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Platea dictumst quisque sagittis purus sit amet volutpat consequat. Aliquam malesuada bibendum arcu vitae. At volutpat diam ut venenatis tellus in metus vulputate. Senectus et netus et malesuada fames.
                </p>
                <p className="text text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Platea dictumst quisque sagittis purus sit amet volutpat consequat. Aliquam malesuada bibendum arcu vitae. At volutpat diam ut venenatis tellus in metus vulputate. Senectus et netus et malesuada fames.
                </p>

                </div>
            </div>

        <hr/>

        <div className="row">
            <div className="col-md-6 mb-4">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Courage</h5>
                <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod quis viverra nibh cras pulvinar mattis. Dis parturient montes nascetur ridiculus mus mauris vitae. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Amet commodo nulla facilisi nullam vehicula ipsum.</p>
                </div>
            </div>
            </div>
            <div className="col-md-6 mb-4">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Orientation</h5>
                <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod quis viverra nibh cras pulvinar mattis. Dis parturient montes nascetur ridiculus mus mauris vitae. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Amet commodo nulla facilisi nullam vehicula ipsum.</p>
                </div>
            </div>
            </div>
        </div>

        <hr/>

        <div className="row">
            <div className="col-md-6 mb-4">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Reality</h5>
                <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod quis viverra nibh cras pulvinar mattis. Dis parturient montes nascetur ridiculus mus mauris vitae. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Amet commodo nulla facilisi nullam vehicula ipsum.</p>
                </div>
            </div>
            </div>
            <div className="col-md-6 mb-4">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Endurance</h5>
                <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod quis viverra nibh cras pulvinar mattis. Dis parturient montes nascetur ridiculus mus mauris vitae. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Amet commodo nulla facilisi nullam vehicula ipsum.</p>
                </div>
            </div>
            </div>
        </div>

        <hr/>

    </div>
    );
};

export default About;