import React from 'react';
import './Featurettes.css';
import '../styles/fonts.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';
import FeatureImage1 from '../assets/images/featurette-1.png';
import FeatureImage2 from '../assets/images/featurette-2.png';
import FeatureImage3 from '../assets/images/featurette-3.png';
import FeatureImage4 from '../assets/images/featurette-4.png';
import FeatureImage5 from '../assets/images/featurette-5.png';

const Featurettes: React.FC = () => {
    return (
        <div className='featurettes container f-top'>
            

            <div className="row featurette">
            <div className="col-md-7">
                <h2 className="featurette-heading fw-normal lh-1">AI/ML Implementation. <span className="text-body-secondary">Transforming Businesses, One Algorithm at a Time.</span></h2>
                <p className="lead text-start">Artificial Intelligence (AI) and Machine Learning (ML) are not just buzzwords – they are real, powerful tools that are revolutionizing industries across the globe. By learning from data and making intelligent predictions and decisions, AI/ML technologies are helping businesses enhance efficiency, drive innovation, and create unique customer experiences. Our cutting-edge AI/ML implementation strategies are designed to navigate complex challenges and bring about transformative change. Embark on your AI/ML journey with us and unlock the true potential of your data.</p>
            </div>

                <div className="col-md-5">
                    <Link to="/ai-ml" onClick={scrollToTop}>
                    <img 
                        className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded" 
                        width="500" 
                        height="500" 
                        src={FeatureImage1}  
                        aria-label="Feature Image 1" 
                        alt="Future USCIS HQ"
                    />
                    </Link>
                </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row featurette">
            <div className="col-md-7 order-md-2">
                <h2 className="featurette-heading fw-normal lh-1">The Power of Decision Sciences. <span className="text-body-secondary">Turning Data into Actionable Insight.</span></h2>
                <p className="lead text-start">Decision Sciences is the interdisciplinary approach of applying quantitative methods to inform strategic, tactical, and operational decisions. We specialize in harnessing the power of advanced analytics, statistics, and predictive modeling to help our clients make data-driven decisions that propel their businesses forward. With our expertise in Decision Sciences, you're not just getting numbers and reports - you're getting a roadmap to success, tailored specifically to your company's needs. Experience the advantage of data-driven decision making with us.</p>
            </div>

                <div className="col-md-5 order-md-1">
                <Link to="/decision-sciences" onClick={scrollToTop}>
                <img 
                    className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded" 
                    width="500" 
                    height="500" 
                    src={FeatureImage2}  
                    aria-label="Feature Image 2" 
                    alt="Future US Immigration transport docking on earth"
                    />
                </Link>
                </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row featurette f-bottom">
            <div className="col-md-7">
                <h2 className="featurette-heading fw-normal lh-1">Innovation through Technology Modernization. <span className="text-body-secondary">Transforming Your Legacy Systems.</span></h2>
                <p className="lead text-start">Technology Modernization is no longer an option, but a necessity to stay relevant in today's fast-paced digital era. Our team at KirkpatrickCORE is committed to helping your business replace or upgrade your outdated systems and applications with cutting-edge technology solutions. We blend our deep industry knowledge with technical expertise to modernize your IT infrastructure, thereby enhancing operational efficiency, increasing productivity, and driving business growth. Whether it's migrating to the cloud, incorporating AI, or modernizing your application architectures, we ensure a seamless transition with minimal business disruption. Partner with us to unlock the full potential of modern technology for your business.</p>
            </div>

                <div className="col-md-5">
                <img 
                    className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded" 
                    width="500" 
                    height="500" 
                    src={FeatureImage3}  
                    aria-label="Feature Image 3" 
                    alt="Future USCIS OK city branch office."
                    />
                </div>
            </div>

            <hr className="featurette-divider" />            

            <div className="row featurette">
            <div className="col-md-7 order-md-2">
                <h2 className="featurette-heading fw-normal lh-1">Steer Towards Success. <span className="text-body-secondary">Agile Transformation at its Finest.</span></h2>
                <p className="lead text-start">At KirkpatrickCORE, we understand that agility is more than just a buzzword - it's a methodology that can revolutionize your entire business operation. Our Agile Transformation services are designed to transition your business from traditional processes to Agile practices that adapt swiftly to the ever-changing market conditions. We empower your teams to work collaboratively, delivering value to your customers faster and more efficiently. From adopting Agile frameworks such as Scrum and Kanban to training your team and implementing the right Agile tools, we provide comprehensive support for your transformation journey. Experience increased productivity, faster time to market, and improved stakeholder satisfaction with our Agile Transformation services.</p>
            </div>

                <div className="col-md-5 order-md-1">
                <img 
                    className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded" 
                    width="500" 
                    height="500" 
                    src={FeatureImage4}  
                    aria-label="Feature Image 4" 
                    alt="Future USCIS ATL branch office."
                    />
                </div>
            </div>

            <hr className="featurette-divider" />
            
            <div className="row featurette f-bottom">
            <div className="col-md-7">
                <h2 className="featurette-heading fw-normal lh-1">Boosting Development Efficiency. <span className="text-body-secondary">Welcome to Managed DevSecOps.</span></h2>
                <p className="lead text-start">At KirkpatrickCORE, we transform how software development pipelines function with our innovative Managed DevSecOps service. 
                We understand that robust software security and accelerated development go hand-in-hand, not in isolation. 
                Our service, powered by expert strategies and machine learning, 
                ensures a seamless integration of security into every stage of your software development lifecycle. 
                Our systems monitor processes, provide real-time feedback, and adapt to project progress, 
                enabling rapid, secure software releases. We integrate automated testing, compliance checks, 
                and security best practices into a unified platform. With our Managed DevSecOps, 
                secure and efficient software deployment becomes an achievable outcome rather than a challenging goal. 
                Experience the difference that technology-driven DevSecOps can make for your business.</p>
            </div>


                <div className="col-md-5">
                <Link to="/managed-devsecops" onClick={scrollToTop}>
                <img 
                    className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded" 
                    width="500" 
                    height="500" 
                    src={FeatureImage5}  
                    aria-label="Feature Image 5" 
                    alt="Future Immigration Transport in orbit around earth."
                    />
                </Link>
                </div>
            </div>

            <hr className="featurette-divider" />

        </div>
    );
};

export default Featurettes;