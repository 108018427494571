import React from 'react';
import './Divider.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Divider: React.FC = () => {
    return (
        <div className="b-example-divider"></div>
    );
};

export default Divider;