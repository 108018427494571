import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './AiMl.css';

const AiMl: React.FC = () => {
    return (
        <div className="container my-5">
            <div className="row mb-4">
                <div className="col">
                    <div className="aiml-background-image d-flex align-items-center justify-content-center rounded">
                        <h1 className="text-center text-white">Artificial Intelligence & Machine Learning</h1>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-lg mb-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Open Source AI Models & Tools</h5>
                    <p className="card-text">
                        We offer solutions utilizing cutting-edge open source AI models and tools, enabling businesses to leverage state-of-the-art technology without high licensing costs. 
                    </p>
                    </div>
                </div>
                </div>

                <div className="col-lg mb-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Transfer Learning Models</h5>
                    <p className="card-text">
                        Transfer learning allows us to harness pre-trained models to your benefit, vastly reducing the time and resources required for training, while maintaining a high level of accuracy.
                    </p>
                    </div>
                </div>
                </div>

                <div className="col-lg mb-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">ML Ops</h5>
                    <p className="card-text">
                        Our MLOps services facilitate the production of machine learning algorithms and operations, ensuring the successful integration and maintenance of AI in your business.
                    </p>
                    </div>
                </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-lg mb-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">Cloud Services</h5>
                    <p className="card-text">
                        We specialize in utilizing AWS Sagemaker and Azure AI tools to facilitate your AI and ML operations in the cloud, providing scalability, security and seamless integration.
                    </p>
                    </div>
                </div>
                </div>

                <div className="col-lg mb-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">HuggingFace Transformers & Pytorch</h5>
                    <p className="card-text">
                        Our expertise in HuggingFace Transformers and PyTorch allows us to create robust, flexible and efficient neural network models tailored to your business needs.
                    </p>
                    </div>
                </div>
                </div>

                <div className="col-lg mb-4">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">TensorFlow Solutions</h5>
                    <p className="card-text">
                        We use TensorFlow, a leading machine learning framework, to build and deploy AI models, enabling faster insights and decision-making for your business.
                    </p>
                    </div>
                </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col">
                <h2 className="text-center">Serving the Federal Government</h2>
                <p>
                    We provide services to numerous federal government agencies including the DHS USCIS, DOJ, DoD, DoE, DoS, and DoL. We ensure the proper handling and protection of all classified and top secret information.
                </p>
                </div>
            </div>
        </div>
    );
};

export default AiMl;
