import React, { useEffect, useRef } from 'react';
import './Carousel.css';
import '../styles/fonts.css';
import KcoreLogo from '../assets/images/kirkpatrickcore-service-logo-indigo.png';
import '../App.css';
import { Link } from 'react-router-dom';

const colors = [
  [252, 252, 251],
  [51, 51, 163],
  [190, 50, 208],
  [254, 230, 133],
  [23, 23, 22],
  [252, 252, 251]
];

const Carousel: React.FC = () => {

  const step = useRef(0);
  const colorIndices = useRef([0,1,2,3]);
  const gradientSpeed = 0.004;

  const logoRef = useRef<HTMLImageElement | null>(null);
  const gradientRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
      const interval = setInterval(() => {

          const c0_0 = colors[colorIndices.current[0]];
          const c0_1 = colors[colorIndices.current[1]];
          const c1_0 = colors[colorIndices.current[2]];
          const c1_1 = colors[colorIndices.current[3]];
      
          const istep = 1 - step.current;
          const r1 = Math.round(istep * c0_0[0] + step.current * c0_1[0]);
          const g1 = Math.round(istep * c0_0[1] + step.current * c0_1[1]);
          const b1 = Math.round(istep * c0_0[2] + step.current * c0_1[2]);
          const color1 = "rgb("+r1+","+g1+","+b1+")";
      
          const r2 = Math.round(istep * c1_0[0] + step.current * c1_1[0]);
          const g2 = Math.round(istep * c1_0[1] + step.current * c1_1[1]);
          const b2 = Math.round(istep * c1_0[2] + step.current * c1_1[2]);
          const color2 = "rgb("+r2+","+g2+","+b2+")";
      
          if (logoRef.current) {
              logoRef.current.style.background = `linear-gradient(to right, ${color1} , ${color2})`;
          }

          if (gradientRef.current) {
              gradientRef.current.style.background = `linear-gradient(to right, ${color1} , ${color2})`;
          }

          step.current += gradientSpeed;
          if ( step.current >= 1 ) {
              step.current %= 1;
              colorIndices.current[0] = colorIndices.current[1];
              colorIndices.current[2] = colorIndices.current[3];
              
              colorIndices.current[1] = ( colorIndices.current[1] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;
              colorIndices.current[3] = ( colorIndices.current[3] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;
          }

      }, 10);

      return () => {
          clearInterval(interval);
      }
  }, []);


    return (
        <div className="carousel container">
            <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row">
                            <div className="col order-1 text-start">
                                <h1 className="home">Empowering Humanity: Pioneering Artificial Intelligence & Machine Learning to Improve Lives</h1>
                                <p className="p-home">We don't just build cutting-edge technology - we create digital solutions that empower individuals, 
                                    elevate communities, and catalyze positive change. 
                                </p>
                                <button className="btn home-btn" type="button">
                                <Link to="/about" className="link-unstyled btn-outline-light">Learn more</Link>
                                </button>
                            </div>
                            <div className="col order-md-2">
                                <div className="logo-container">
                                    <div ref={gradientRef} className="gradient">
                                        <img ref={logoRef} src={KcoreLogo} id="logo" alt="KirkpatrickCORE Logo 1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carousel;

