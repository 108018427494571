import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './Terms.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';

const Terms: React.FC = () => {
    return (
        <div className="container">
            <br />
            <h2 className="text-start">Terms of Use</h2>
            <br />
            <h3 className="text-start">Introduction</h3>
            <p className="text-start">These terms of use (‘Terms’) will apply when you access or use our website. 
                We kindly ask you to read these Terms carefully. 
                By accessing or using the website or otherwise indicating your consent, you will be considered to have agreed to these Terms.  
                We may make changes to these Terms from time to time, so please check back here regularly. 
                These Terms were last updated on 9 August 2021.</p>
            <br />
            <hr />
            <h3 className="text-start">Definitions</h3>
            <p className="text-start">In these Terms, references to ‘we’, ‘us’ or ‘our’ will mean the KirkpatrickCORE, LLC company registration number *************.  
                KirkpatrickCORE℠ and KCORE℠ is a wholly owned subsidiary of KirkpatrickCORE, LLC. 
                Any reference to ‘you’ means the person accessing or using the website. 
                ‘Content’ means any information, data, text, software, photographs, graphics, video or other materials on or provided through the website. 
                ‘Website’ means <a href="https://kirkpatrickcore.com">kirkpatrickcore.com</a> and all associated web pages.</p>
            <br />
            <hr />
            <h3 className="text-start">Access to the website</h3>
            <p className="text-start">Please ensure that your computer system meets all the necessary technical specifications to enable you to access and use the website and is compatible with the website. 
                We may, from time to time, restrict access to certain features, parts or Content of the website (for instance, API) to users who have registered with us. 
                While we try to make sure that the website is accurate, up-to-date and free from bugs, we cannot promise that it will be. 
                While we try to make sure that the website is available for your use, 
                we do not promise that the website is available at all times nor do we promise the uninterrupted use by you of the website. 
                We may prevent or suspend your access to the website if you do not comply with any part of these Terms.</p>
            <br />
            <hr />
            <h3 className="text-start">Use of Content</h3>
            <p className="text-start">Content is provided for your personal information purposes only to inform you about us and our products and news, 
                features, services and other websites that may be of interest. 
                It does not constitute technical or any other type of advice and should not be relied on for any purposes.  
                You may view, download, print and make a copy of the Content on a computer which is accessible only by yourself.</p>
            <br />
            <p className="text-start">Where copy of the Content are made, you shall ensure that: (i) KirkpatrickCORE, LLC is acknowledged as the source of the Contents; 
                (ii) the Content must be copied without any editing or amendment; 
                (iii) any copyright or intellectual property marks must not be altered or removed; and, 
                (iv) the person that is intended to receive the copy must be informed that these provisions also apply to that person.</p>
            <br />
            <p className="text-start">You are not authorized to do the following: (i) use the website, the Content for any commercial gain or for any other benefit; 
                (ii) modify or interfere with the website by any means, including through the use of code that is harmful; or 
                (iii) use the website to defame, abuse or otherwise violate the rights of others, including their intellectual property rights.</p>
            <br />
            <hr />
            <h3 className="text-start">Hyperlinks and third party sites</h3>
            <p className="text-start">The website may contain hyperlinks or references to third party websites. 
                Any such hyperlinks or references are provided for your convenience only. 
                We have no control over third party websites and accept no legal responsibility for any content, material or information contained in them. 
                The display of any hyperlink and reference to any third party website does not mean that we endorse that third party’s website, products or services. 
                Your use of a third party site may be governed by the terms and conditions of that third party site.</p>
            <br />
            <hr />
            <h3 className="text-start">Intellectual property rights</h3>
            <p className="text-start">The website, the Content and any intellectual property rights in the website and Content are the exclusive property of KirkpatrickCORE, LLC. 
                and our licensors. Except as expressly set out here, 
                nothing in these terms of use gives you any rights in respect of any intellectual property 
                owned by us or our licensors and you acknowledge that you do not acquire any 
                ownership rights by downloading content from the website.</p>
            <br />
            <hr />
            <h3 className="text-start">Your Privacy</h3>
            <p className="text-start">Your privacy and the security of your personal information is important to us. 
                We will comply with all data protection laws in force in the United States of America
                 and will only collect and use your personal information in accordance with our Privacy Policy.</p>
            <br />
            <hr />
            <h3 className="text-start">Governing Law</h3>
            <p className="text-start">Your access and use of the website and Content (and any legal issue arising from such access and use) are governed by the law of the United States of America.</p>
            <br />
            <p className="text-start">If you are uncertain about anything in these Terms please contact us via email <Link to="/contact" onClick={scrollToTop}>here</Link>.</p>
            <br />
            <hr />
        </div>
    );
};

export default Terms;