import React, { useState } from 'react';
import './Footer.css';
import '../styles/fonts.css';
import { Linkedin, Github, Boxes } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';
import axios from 'axios';

//require('dotenv').config(); // <- Load environment variables

const Footer: React.FC = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const isEmailValid = (email: string) => {
        const re = /^\S+@\S+\.\S+$/;
        return re.test(email);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isEmailValid(email)) {
            //let apiURL;
            //if (process.env.NODE_ENV === "development") {
            //    apiURL = "http://localhost:3001";
            //} else if (process.env.NODE_ENV === "production") {
            //    apiURL = "https://your-domain-name-or-ip-address"; // Replace with your production server address
            //}

            // Sending email to the server
            //axios.post(`${apiURL}/api/newsletter`, { email }) // <- Axios POST request
            axios.post("/api/newsletter", { email })
                .then(response => {
                    console.log({email});
                    setEmail("");
                    setError("");
                    setShowSuccess(true);
                    setTimeout(() => setShowSuccess(false), 3000);
                })
                .catch(error => {
                    console.error("There  was an error!", error);
                });
        } else {
            setError("Invalid email address");
            setEmail("");
            setShowError(true);
            setTimeout(() => setShowError(false), 3000);
        }
    };

    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
    <footer className="py-5 gradient-footer">
        <div className="container">
            <div className="row">
                <div className="col-6 col-md-2 mb-3 mx-3 text-start">
                    <h5 className="h-footer">Links</h5>
                    <ul className="nav flex-column">
                    <li className="mb-2"><a href="/#top" className="nav-link-footer p-0 text-body-secondary">Home</a></li>
                    <li className="mb-2"><a href="/#top" className="nav-link-footer p-0 text-body-secondary">Services</a></li>
                    <li className="mb-2">
                        <Link to="/about" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            About
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/blog" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Blog
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/careers" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Careers
                        </Link>
                    </li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 mb-3 mx-3 text-start">
                    <h5 className="h-footer">Solutions</h5>
                    <ul className="nav flex-column">
                    <li className="mb-2">
                        <Link to="/ai-ml" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Artificial Intelligence
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/decision-sciences" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Decision Sciences
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/technology-modernization" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Technology Modernization
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/agile-transformation" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Agile Transformation
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/managed-devsecops" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Managed DevSecOps
                        </Link>
                    </li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 mb-3 text-start mx-3">
                    <h5 className="h-footer">Company</h5>
                    <ul className="nav flex-column">
                    {/*<li className="mb-2"><a href="/#top" className="nav-link-footer p-0 text-body-secondary"></a></li>*/}
                    <li className="mb-2">
                        <Link to="/aegis" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Aegis
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/contact" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Contact
                        </Link>
                    </li>
                    <li className="mb-2"><a href="/#top" className="nav-link-footer p-0 text-body-secondary">FAQs</a></li>
                    <Link to="/terms" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Terms
                        </Link>
                    <li className="mb-2">
                        <Link to="/trust" className="nav-link-footer p-0 text-body-secondary" onClick={scrollToTop}>
                            Trust
                        </Link>
                    </li>
                    </ul>
                </div>

                <div className="col-md-4 offset-md-1 mb-3">
                    <form id="form-footer" onSubmit={handleSubmit}>
                        <h5 className="h-footer">Subscribe to our newsletter</h5>
                        <p className="li-footer">Monthly digest of what's new and exciting from KirkpatrickCORE.</p>
                        <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                            <label htmlFor="kcore-newsletter" className="visually-hidden">Email address</label>
                            <input id="kcore-newsletter" type="text" className="form-control" placeholder="Email address" required
                            value={email} onChange={(e) => setEmail(e.target.value)} />
                            <button className="btn footer-btn" type="submit">Subscribe</button>
                        </div>
                        {showError && <div className="text-danger fadeout">{error}</div>}
                        {showSuccess && <div className="text-success fadeout">Thank you for subscribing!</div>}
                    </form>
                </div>
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between py-1 border-top">
                <p className="p-footer mx-3">Copyright &copy; {getYear()} KirkpatrickCORE, LLC. | All rights reserved.</p>
                <ul className="list-unstyled d-flex mx-3">
                    <li className="ms-3">
                        <a className="link-body-emphasis" href="https://www.linkedin.com/company/kirkpatrickcore">
                            <Linkedin className="footer-icons" width="24" height="24" />
                        </a>
                    </li>
                    <li className="ms-3">
                        <a className="link-body-emphasis" href="https://github.com/KirkpatrickCORE">
                            <Github className="footer-icons" width="24" height="24" />
                        </a>
                    </li>
                    <li className="ms-3">
                        <a className="link-body-emphasis" href="https://repo1.dso.mil/dsop/solutions-delivery-platform/sonar-scanner/sonar-scanner">
                            <Boxes className="footer-icons" width="24" height="24" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
    );
};

export default Footer;