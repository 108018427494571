import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DecisionSci.css';
import '../styles/fonts.css';

const DecisionSci: React.FC = () => {
    return (
        <div className="container py-5">
            <div className="row mb-4">
                <div className="col">
                    <div className="decision-sci-background-image d-flex align-items-center justify-content-center rounded">
                        <h1 className="text-center text-white">Decision Sciences</h1>
                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className="col">
                    <h2>Our Expertise</h2>
                    <p>
                        With a diverse team of experts in various fields of Decision Sciences, we offer our federal clients like the DOJ Executive Office of Immigration Review (EOIR) and the DHS USCIS IRIS directorate a broad range of capabilities.
                    </p>
                </div>
            </div>

            <div className="row my-5">
                <div className="col-md-4">
                    <h3>Analytics Hierarchical Process</h3>
                    <p>
                        We employ AHP to prioritize and make decisions in complex scenarios. AHP helps to capture both subjective and objective aspects of a decision.
                    </p>
                </div>
                <div className="col-md-4">
                    <h3>Decision Tree Models</h3>
                    <p>
                        We use Decision Trees to aid in decision making and forecasting. This method helps in visualizing the various alternatives and the potential outcomes of a decision.
                    </p>
                </div>
                <div className="col-md-4">
                    <h3>Naive Bayes Models</h3>
                    <p>
                        Our team utilizes Naive Bayes models to perform multiclass classification. This probabilistic classifier, based on Bayes' theorem, is particularly effective in decision making involving multiple classes.
                    </p>
                </div>
            </div>

            <div className="row my-5">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                        <h2>Game Theory Frameworks</h2>
                        <p>
                            Game theory is a critical aspect of our decision science services. Here are six frameworks we use frequently:
                        </p>

                        <ul>
                            <li>Cooperative / Non-cooperative games</li>
                            <li>Zero-sum / Non-zero-sum games</li>
                            <li>Simultaneous / Sequential games</li>
                            <li>Perfect information / Imperfect information games</li>
                            <li>Combinatorial games</li>
                            <li>Infinitely long games</li>
                        </ul>
                        <p>
                            We tailor our approach based on the unique needs of the situation, offering a customized decision science solution for each client.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DecisionSci;
