import React  from 'react';
import './Navigation.css';
import logo from './9.png'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';

const closeMenu = () => {
  const navBarToggler = document.querySelector('.navbar-toggler');
  if (navBarToggler) {
      const navBarCollapse = document.getElementById('navbarSupportedContent');
      if (navBarCollapse && navBarCollapse.classList.contains('show')) {
          (navBarToggler as HTMLElement).click();
      }
  }
};



const Navigation: React.FC = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light gradient-custom">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={logo} className="rounded" alt="KirkpatrickCORE" width="72" height="72" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item me-3">
              <a className="nav-link" href="/" onClick={closeMenu}>Home</a>
            </li>
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.preventDefault()}>
                  Services
              </button>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link to="/ai-ml" className="dropdown-item" onClick={() => {scrollToTop(); closeMenu();}}>Artificial Intelligence</Link></li>
                <li><Link to="/decision-sciences" className="dropdown-item" onClick={() => {scrollToTop(); closeMenu();}}>Decision Sciences</Link></li>
                <li><Link to="/technology-modernization" className="dropdown-item" onClick={() => {scrollToTop(); closeMenu();}}>Technology Modernization</Link></li>
                <li><Link to="/agile-transformation" className="dropdown-item" onClick={() => {scrollToTop(); closeMenu();}}>Agile Transformation</Link></li>
                <li><Link to="/managed-devsecops" className="dropdown-item" onClick={() => {scrollToTop(); closeMenu();}}>Managed DevSecOps</Link></li>
              </ul>
            </li>
            <li className="nav-item me-3">
              <Link to="/about" className="nav-link" onClick={closeMenu}>About</Link>
            </li>
            <li className="nav-item me-3">
              <Link to="/blog" className="nav-link" onClick={closeMenu}>Blog</Link>
            </li>
            <li className="nav-item me-3">
              <Link to="/aegis" className="nav-link" onClick={closeMenu}>Aegis</Link>
            </li>
            <li className="nav-item me-3">
              <Link to="/careers" className="nav-link" onClick={closeMenu}>Careers</Link>
            </li>
            <li className="nav-item">
              <button type="button" className="btn btn-outline-light">
                <Link to="/contact" className="link-unstyled btn-outline-light" onClick={closeMenu}>Contact</Link>
              </button>              
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
