import React from 'react';
import './CallToAction.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';

const CallToAction: React.FC = () => {
  return (
    <div className="call-to-action py-5 text-center gradient-call-to-action">
      <div className="call-to-action container gradient-call-to-action">
        <h1 className="mb-4 h-call-to-action">Discover the Power of Modern Technology</h1>
        <p className="lead mb-4">
          Unleash the potential of your business with our innovative solutions
          in artificial intelligence, technology modernization, and agile
          transformation. Let us help you stay ahead of the curve.
        </p>
        <Link to="/contact"><button className="btn btn-outline-light" type="button" onClick={scrollToTop}>Connect Now</button></Link>
      </div>
    </div>
  );
};

export default CallToAction;
