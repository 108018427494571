import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './ManagedDevOps.css';

const WellnessTech: React.FC = () => {
    return (
        <div className="container my-5">
            <div className="row mb-4">
                <div className="col">
                    <div className="managed-devops-background-image d-flex align-items-center justify-content-center rounded">
                        <h1 className="text text-start-center text text-start-white">Managed DevSecOps</h1>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row mt-5">
                <div className="col-md-8 mx-auto">
                    <h3 className="mb-4">Managed DevSecOps: Streamlining Your Development Pipeline</h3>
                    <p className="text text-start">
                        At the heart of our Managed DevSecOps is a commitment to integrating security into every step of your software development lifecycle. 
                        This not only accelerates development but also instills a culture of shared responsibility, enhancing the security of your systems and data.
                    </p>
                    <p className="text text-start">
                        By managing DevSecOps as a service, we help organizations create a seamless pipeline from development to deployment, minimizing friction and maximizing efficiency. 
                        Our team of experienced engineers will work with you to implement best practices, automate processes, and ensure compliance with security regulations.
                    </p>
                    <p className="text text-start">
                        Our Managed DevSecOps service is not just about keeping your software secure; it's about enabling your team to deliver quality, secure software faster and more reliably. 
                        We provide you with the tools and practices needed to integrate security throughout your software development lifecycle.
                    </p>
                    <p className="text text-start">
                        Embrace the future of software development with our Managed DevSecOps service. 
                        Let us help you build more, secure more, and worry less.
                    </p>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Comprehensive Security</h5>
                            <p className="card-text text-start">From threat modeling and code review to security testing and compliance audits, our DevSecOps approach addresses security at every stage of your development lifecycle.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Automation and Efficiency</h5>
                            <p className="card-text text-start">Our Managed DevSecOps service leverages automation to increase the speed and reliability of your software releases, while reducing the risk of human error.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Expert Support</h5>
                            <p className="card-text text-start">With our team of experienced DevSecOps engineers, you'll get expert guidance, ongoing support, and the peace of mind that comes with knowing your software is secure and compliant.</p>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

        </div>
    );
};

export default WellnessTech;
