import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './Trust.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';

const Trust: React.FC = () => {
    return (
        <div className="container">
            <br/>
            <h2 className="text-start">KirkpatrickCORE℠ Web Site Trust & Privacy Policy</h2>
            <br />
            <h4 className="text-start">Learn about how we handle your data collected through this website and our marketing activities.</h4>
            <h4 className="text-start">Last updated: June 21, 2023</h4>
            <br />
            <p className="text-start">We at KirkpatrickCORE, LLC ("KCORE" "KirkpatrickCORE” “we” or “us“) are fully 
                committed to protecting the personal information that you share with us. We believe that you 
                have a right to know our practices regarding the information we collect and use when you use 
                our website. To that extent we have adopted the following privacy policy explaining our data 
                collection and usage practices.
                Please note that this privacy policy relates to the information we receive through: 
                (i) use of the KirkpatrickCORE℠ website available on or through www.kirkpatrickcore.com and; (
                ii) our marketing activities including, registrations as part of webinars, conference events,
                newsletter, emails etc.; and 
                (iii) any other product or activity that links to or otherwise references this privacy policy 
                (collectively, “Site”).  This is applicable to those who visit the Site (“You” or “User”).</p>
            <br />
            <p className="text-start">We encourage you to carefully read this Trust & Privacy Policy, where you can learn about the 
                information we collect, how we use it, the controls we give you over your information and 
                the measures we take to keep it safe.</p>
            <br />
            <hr />
            <h3 className="text-start">Our Privacy Policy Covers:</h3>
            <ol className="text-start">
                <li>What Information We Collect</li>
                <li>How We Collect Information from Our Users</li>
                <li>How We Use the Information</li>
                <li>How We Share Your Information</li>
                <li>Third Party Service Providers</li>
                <li>Our use of Third Party Websites</li>
                <li>Your Right to Access and Control Your Personal Information</li>
                <li>Data Retention</li>
                <li>Information Security</li>
                <li>Cookie Policy</li>
                <li>Minors</li>
                <li>European Union Members – International Transfer of Data</li>
                <li>California Residents</li>
                <li>How We Respond to No Track Signals</li>
                <li>Changes to the Privacy Policy</li>
                <li>How to Contact Us</li>
            </ol>
            <br />
            <hr />
            <h3 className="text-start">1. What Information We Collect</h3>
            <p className="text-start">We may collect technical or behavioral information about your visit to the Site. Technical Information includes the type of operating system, type of browser, screen resolution, browser and keyboard language. Behavioral Information includes information related to your use of our Site, including where you clicked, scrolled, focused (i.e. zoom), and navigated to on the Site and on a webpage. It may also include information related to whether you received, opened or clicked on any links in an email sent to you. We gather, collect and record the information relating to such usage, either directly from you or through the help of third-party service providers as detailed below.</p>
            <br />
            <p className="text-start">The second type of information is individually identifiable information (“Personal Information“). This information identifies or may identify an individual, when put together with other information about such individual. Such information includes Name, Email, Phone Number, Address or other contact details or personal content that you provide to us whether through a form or field on our Site or any other communication (e.g. email, phone, post etc.). Users of the Site automatically provide their IP address.
                For avoidance of doubt, any technical or behavioral Information connected or linked to any Personal Information shall be deemed Personal Information as long as such connection or linkage exists.</p>
            <br />
            <hr />
            <h3 className="text-start">2. How We Collect Information from Our Users</h3>
            <p className="text-start">We collect Information in a number of different ways, including:</p>
            <ul className="text-start">
                <li>When you voluntarily provide KirkpatrickCORE℠ with information – for example, in any form, for demo request, apply for a position, contact us through our Facebook, LinkedIn or Twitter account, or when you contact us by email or otherwise send us a request for information. This may also include information you provided through your participation in a conference or event that we attend.</li>
                <br />
                <li>Cookies and other similar technologies (e.g. analytical and behavior tracking technologies) as further outlined below (collectively, “Information Technologies”). Information Technologies help us to analyze usage of our website and to provide you with more relevant content. They also help us to deliver more relevant advertisements to you on our own or third party sites. For more information on Information Technologies and how to manage your preferences please see Section 10.</li>
                <br />
                <li>Information obtained from third party sources. This includes information received through marketing tools and services and public information sources such as social media sites. We may combine the information received through these sources with other information we have in order to ensure our records are up to date and so that we are able to provide you with relevant content.</li>
            </ul>
            <br />
            <hr />
            <h3 className="text-start">3. How We Use the Information</h3>
            <p className="text-start">We use the information we collect in order to:</p>
            <ul className="text-start">
                <li>Create aggregated statistical data for research purposes and customization and improvement of the Site and related services.</li>
                <br />
                <li>Reply to your online queries made through the Site or process your requests for additional information.</li>
                <br />
                <li>Analyze your use of the Site in order to personalize and optimize the experience of the website.</li>
                <br />
                <li>Develop new features and services.</li>
                <br />
                <li>Determine general geolocation information (i.e., country) from which the User’s computer is connected to the Internet in order for us to know the general location of our Site’s Users as well as in order to safeguard our Site.</li>
                <br />
                <li>Promote the safety and security of the Site, our Users, and other parties. For example, for authenticating, protect against fraud and abuse, respond to a legal request or claim, conduct audits, and enforce our terms and policies.</li>
                <br />
                <li>Send you marketing materials related to the Platform that we believe may interest you such as a periodic newsletter emails or information about KirkpatrickCORE℠ products and services. In some cases, you may have requested and/or consented to receive such materials. Otherwise we may send you such materials only where we have a lawful basis to do so – for example, when we have a legitimate interest to market our products to you. In any event, you will have the ability to opt out of receiving such materials at any time.</li>
                <br />
                <li>To the extent necessary for KirkpatrickCORE℠ to comply with its legal obligations and to exercise and enforce its legal rights.</li>
            </ul>
            <br />
            <hr />
            <h3 className="text-start">4. How We Share Your Information</h3>
            <p className="text-start">KirkpatrickCORE℠ may transfer and disclose non-personal information or aggregated non-identifiable information to third parties at its own discretion.</p>
            <br />
            <p className="text-start">We will not share any Personal Information with any third party, other than in the following cases:</p>
            <ul className="text-start">
                <li>When you agree or direct us to share with our subsidiary companies and affiliates.</li>
                <br />
                <li>To respond to User’s support or inquiry requests.</li>
                <br />
                <li>To KirkpatrickCORE℠ resellers, agents, sales partners or service providers acting on our behalf in connection with the offering of KirkpatrickCORE℠ products.</li>
                <br />
                <li>To detect, prevent, or otherwise address fraud, security or technical issues.</li>
                <br />
                <li>To satisfy any applicable law, regulation, legal process, subpoena or governmental request.</li>
                <br />
                <li> To enforce this Privacy Policy and/or the terms of use of the Site (“TOU”), including investigation of potential violations thereof.</li>
                <br />
                <li>To respond to claims that contact information (e.g. name, email address, etc.) of a third-party had been posted or transmitted without their consent or as a form of harassment.</li>
                <br />
                <li>To protect the rights, property, or personal safety of KirkpatrickCORE℠, its Users, or the general public.</li>
                <br />
                <li>When KirkpatrickCORE℠ is undergoing any change in control, including by means of merger, acquisition or purchase of all or substantially all of its assets. In such a case, information will be transferred following the completion of any such change in control and/or during the assessment process while a change in control is pending.</li>
                <br />
                <li>To assist us with part of our business operations. We do this with partners and contractors, who process information for us, under our instructions, and in compliance with appropriate confidentiality obligations and security measures.</li>
            </ul>
            <p className="text-start">These service providers provide their services globally which means that your data might be transferred outside of your country to a country that does not have the same data protection laws as your jurisdiction</p>
            <br />
            <hr />
            <h3 className="text-start">5. Third Party Service Providers</h3>
            <p className="text-start">We use third party software or services in order to collect, store and/or process the information detailed herein. </p>
            <br />
            <hr />
            <h3 className="text-start">6. Our use of Third Party Websites</h3>
            <p className="text-start">Certain links provided herein permit you to leave this Site and enter non-KirkpatrickCORE℠ World Wide Web sites or resources. These linked sites are provided solely as a convenience to you. These linked sites are not under the control of KirkpatrickCORE℠. Linked sites provide their own terms of use and privacy policy, governing the use of such sites. It is always advisable to read such documents carefully before using those sites.</p>
            <br />
            <hr />
            <h3 className="text-start">7. Your Right to Access and Control Your Personal Information</h3>
            <p className="text-start">You have the right to access, update or remove your Personal Information.</p>
            <br />
            <ul className="text-start">
                <li>You may withdraw your consent from direct marketing at any time or object to our use of your Personal Information for direct marketing purposes. To opt out, click <Link to="/contact" onClick={scrollToTop}>here</Link>.</li>
                <li>If you otherwise communicated with us and provided us with Personal Information you may contact us <Link to="/contact" onClick={scrollToTop}>here</Link>.</li>
            </ul>
            <p className="text-start">If you live in the European Economic Area, United Kingdom, and Switzerland, you have a number of additional rights with respect to your information such as Accessing and Exporting Data, Rectification, Restriction, Consent and Withdrawal, Anonymity, Erasure, Editing and Deleting Data, in certain circumstances you can object to processing of your information, or to completely be deleted from our database. For exercising all these rights, please contact us at the means mentioned above. You also have the right to lodge a complaint with a supervisory authority.</p>
            <br />
            <p className="text-start">Please note that, we may still preserve data for legal reasons or to prevent harm, including as described in the How Information Is Shared section.</p>
            <br />
            <hr />
            <h3 className="text-start">8. Data Retention</h3>
            <p className="text-start">We will only retain your Personal Information for as long as necessary to pursue the purpose of the collection. We may also retain your Personal Information for such time needed to pursue our legitimate business interests, in connection with any legal claims and compliance with legal obligations, or to prevent harm. When we use your information for direct marketing purposes we will retain your data until you opt-out from receiving such materials; provided that we will need to retain certain information to maintain a record of your request.</p>
            <br />
            <hr />
            <h3 className="text-start">9. Information Security</h3>
            <p className="text-start">We take a great care in implementing and maintaining the security of the Site and our Users’ Personal Information. The Personal Information is hosted on our authorized third-party service providers which provide advanced security features. KirkpatrickCORE℠ employs industry standard procedures and policies to ensure the safety of its Users’ Personal Information and prevent unauthorized use of any such information. However, we do not guarantee that unauthorized access will never occur.</p>
            <br />
            <hr />
            <h3 className="text-start">10. Cookie Policy</h3>
            <p className="text-start">When you access the Site, we may use industry-wide information technologies, which store certain information on your computer (“Local Storage“) and which will allow us to enable automatic activation of certain features, improve your browsing experience, and improve our marketing strategies. These technologies are comprised of:</p>
            <ol className="text-start">
                <li>Cookies:A cookie is a small text file transferred to your computer that records or contains certain information about your visit to our website. Such cookies may be either first party or third party cookies. “First party cookies” are such cookies placed by KirkpatrickCORE℠ while visiting the KirkpatrickCORE℠ website. “Third party cookies” are cookies placed by our third party partners when you visit the KirkpatrickCORE℠ Site. This kind of cookie is read each time you visit the Site, and also when you visit certain websites or applications that use similar cookies. Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires, or until you delete your cookies from your browser.</li>
                <br />
                <li>Web Beacons:Web beacons are small pieces of code placed on Web pages, emails or ads that can be used, among other things, to count the users who visit a page, or to understand whether an email or ad was received, opened or any links within it were clicked.</li>
                <br />
                <li>Other Technologies:There are various technologies that provide functionality similar to those provided by cookies and web beacons, such as certain scripts that may be included within a website that help analyze how you use a website.</li>
                <br />
                <li>Please check your browser and browser settings to determine where these types of cookies are stored and whether and how they may be deleted. The Network Advertising Initiative also offers a means to opt-out of a number of advertising cookies. Please visit www.networkadvertising.org to learn more. Most browsers will allow you to erase cookies and other information technologies from your computer’s hard drive, block acceptance of cookies, or receive a warning before a cookie is stored. However, if you block or erase cookies your online experience may be limited or even not possible at all.</li>
            </ol>
            <ul className="text-start">
                <li>Internet Explorer – https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</li>
                <br />
                <li>Mozilla Firefox – https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies</li>
                <br />
                <li>Google Chrome- https://support.google.com/chrome/answer/95647?hl=en</li>
                <br />
                <li>Safari –https://support.apple.com/kb/PH5042?locale=en_US</li>
                <br />
                <li>Opera- https://www.opera.com/help</li>
                <br />
                <li>All other browsers – Please look for a “help” function in the browser or contact the browser provider.</li>
            </ul>
            <br />
            <hr />
            <h3 className="text-start">11. Minors</h3>
            <p className="text-start">You must be over the age of eighteen (18). Therefore, KirkpatrickCORE℠ does not knowingly collect Personal Information from children under the age of eighteen (18) and does not wish to do so. In the event that it comes to our knowledge that a person under the age of eighteen (18) is using the Site, we reserve the right to prohibit and block such User from accessing our Site and will make all efforts to promptly delete any Personal Information.</p>
            <br />
            <hr />
            <h3 className="text-start">12. European Union Members – International Transfer of Data</h3>
            <p className="text-start">For personal data subjects in the European Union, European Economic Area, United Kingdom, and Switzerland, please note that we rely on several legal bases to process the data. First and foremost, we rely on your consent, which you may withdraw at any time as provided above. Where a written commercial agreement exists between us, we process your personal data when the processing is necessary to comply with the terms of  such agreement. Lastly, we may process the data for our legitimate business interests, such as in improving, personalizing, marketing and developing the Site or related services, and for promoting safety and security as described above.</p>
            <br />
            <p className="text-start">Our service is international in scope. By using the KirkpatrickCORE℠ Site or related services, you consent to your information being sent and processed in other jurisdictions, including the United States where there may be less stringent data protection laws. You expressly consent to the use of your personal data in accordance with this Privacy Policy and to transfer of your information outside of the EU, EEA, United Kingdom, and Switzerland, including to the USA.</p>
            <br />
            <hr />
            <h3 className="text-start">13. California Residents</h3>
            <p className="text-start">If you are an individual from California please note that in the preceding twelve (12) months, we may have received information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked with a particular consumer or household (“PII”), including the following categories of information:</p>
            <ul className="text-start">
                <li>Identifiers and other Customer record information such as name, signature, physical characteristics or description, address, unique personal identifier, online identifier, IP address, email address, telephone number, education, or financial information.</li>
                <br />
                <li>Commercial information, including records of services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</li>
                <br />
                <li>Internet or other similar network activity and technical information related to use of mobile device or computer, such as Technical Information (as defined above).</li>
                <br />
                <li>Geolocation data.</li>
                <br />
                <li>Professional or employment-related information, such as employment history, skills certifications, or job titles.</li>
                <br />
                <li>Inferences drawn from other PII, such as preferences, characteristics, behavior, and aptitudes related to a Customer End User’s use of Customer’s applications and services, or Customer’s use of the Site, including Behavioral Information (as defined above).</li>
            </ul>
            <br />
            <p className="text-start">In addition, in the preceding twelve (12) months, KirkpatrickCORE℠ may have disclosed for a business purpose the following types of information: identifiers, PII contained in Customer records, commercial information, professional or employment-related information, Technical Information, geolocation data, and Behavioral Information.</p>
            <br />
            <p className="text-start">California Civil Code Section 1798.83 permits California resident-consumers to request certain information once per year regarding the disclosure of their PII to third parties for purposes of their direct marketing to California resident-consumers. To make such a request, please send us an email at privacy@KirkpatrickCORE℠.ai with “Shine the Light Request” in the subject line. Please note that we evaluate a “Shine the Light” request to determine whether it is proper under the scope of this law before we respond.</p>
            <br />
            <p className="text-start">California residents may have the following additional rights from January 1, 2020:</p>
            <ul className="text-start">
                <li>You have the right to know and right to access information about the categories and specific pieces of PII we have collected about you, as well as the categories of sources from which your PII is collected, the purpose for collecting such information, and the categories of third parties with whom we share your PII. You also have the right to know if we have sold or disclosed your PII. You may also request a copy of the PII we have collected and/or disclosed, and upon request, we will provide this information to you in electronic form.</li>
                <br />
                <li>You have the right to request the deletion of your PII, subject to certain legal exceptions.</li>
                <br />
                <li>You have the right to opt out of the sale of your PII. We do not sell your PII collected on the Site and we do not sell the PII of children under 16.</li>
                <br />
                <li>You have the right to not be discriminated against for exercising any of these rights.</li>
            </ul>
            <p className="text-start">If you would like to exercise one or more of the above rights, please contact us using the contact information provided in Section 16. You may also designate an authorized agent, registered with the California Secretary of State, to make such a request on your behalf. The authorized agent must be registered with the California Secretary of State and must have written permission to submit requests on your behalf. Whenever feasible, we will match the provided identifying information to the PII already maintained by us for verification purposes. If we cannot verify the identity of the requester using the information in our possession, however, we may request additional information to verify your identity.</p>
            <br />
            <hr />
            <h3 className="text-start">14. How We Respond to No Track Signals</h3>
            <p className="text-start">The “Do Not Track” (“DNT”) privacy preference is an option that may be made in some web browsers allowing you to opt-out of tracking by websites and online services. At this time, global standard DNT technology is not yet finalized and not all browsers support DNT. We therefore do not recognize DNT signals and do not respond to them.</p>
            <br />
            <hr />
            <h3 className="text-start">15. Changes to the Privacy Policy</h3>
            <p className="text-start">The terms of this Privacy Policy will govern the use of the Site and any information collected therein. KirkpatrickCORE℠ reserves the right to change this policy at any time, so please re-visit this page frequently. We will provide notice of substantial changes of this policy on the homepage of the Site and/or we will send you an email regarding such changes to the email address that you provided us with, if applicable. Such substantial changes will take effect seven (7) days after such notice was provided on our Site or sent by email (if applicable). Otherwise, all other changes to this Privacy Policy are effective as of the stated “Last Revised” date and your continued use of the Site after the Last Updated date will constitute acceptance of, and agreement to be bound by, those changes.</p>
            <br />
            <hr />
            <h3 className="text-start">16. How to Contact Us</h3>
            <p className="text-start">If you have any questions (or comments) concerning this Privacy Policy, you are welcome to contact us <Link to="/contact">here</Link>.</p>
            <br />
            <hr />
        </div>
    );
};

export default Trust;