import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Divider from './components/Divider';
import CallToAction from './components/CallToAction';
import Home from './containers/Home';
import AiMl from './pages/AiMl';
import DecisionSci from './pages/DecisionSci';
import TechMod from './pages/TechMod';
import Contact from './pages/Contact';
import AgileTrans from './pages/AgileTrans';
import ManagedDevOps from './pages/ManagedDevOps';
import About from './pages/About';
import Aegis from './pages/Aegis';
import Blog from './pages/Blog';
import Careers from './pages/Careers';
import Trust from './pages/Trust';
import Terms from './pages/Terms';

function App() {
  return (
    <Router>   
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ai-ml" element={<AiMl />} />
          <Route path="/decision-sciences" element={<DecisionSci />} />
          <Route path="/technology-modernization" element={<TechMod />} />
          <Route path="/agile-transformation" element={<AgileTrans />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/managed-devsecops" element={<ManagedDevOps />} />
          <Route path="/about" element={<About />} />
          <Route path="/aegis" element={<Aegis />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/trust" element={<Trust />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Divider />
        <CallToAction />
        <Divider />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
