import React, { useState, useEffect, useRef, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './Contact.css';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as  L from 'leaflet';
import SalesImage from '../assets/images/sales.png';
import TechSupportImage from '../assets/images/tech_support.png';
import CareerSupportImage from '../assets/images/career_support.png';
import axios from 'axios';



interface SetViewOnClickProps {
    coords: [number, number];
}

const flagIcon = L.icon({
    iconUrl: require('../assets/images/flag.png'),
    iconSize: [25, 41],
    iconAnchor: [33.95, -83.38],
});

const MyMarker = ({ position, icon, children }: { position: L.LatLngExpression, icon: L.Icon, children?: React.ReactNode }) => {
    const markerRef = useRef<L.Marker | null>(null);
    const eventHandlers = useMemo(() => ({
        add() {
            if (markerRef.current) {
                markerRef.current.setIcon(icon);
            }
        },
    }), [icon]);

    return <Marker ref={markerRef} position={position} eventHandlers={eventHandlers}>{children}</Marker>;
};

const SetViewOnClick: React.FC<SetViewOnClickProps> = ({ coords }) => {
    const map = useMap();

    useEffect(() => {
        map.setView(coords, 13);
    }, [coords, map]);

    return null;
}
  
const Contact: React.FC = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const position: [number, number] = [33.975, -83.37];

    return (
        <section className="container my-5">
            <div className="row mb-4">
                <div className="col">
                    <div className="contact-background-image d-flex align-items-center justify-content-start rounded">
                        <div className="col-md-5">
                            <h1 className="text text-start-center">Contact us</h1>
                            <p className="contact-hero-text text-start">
                            Thank you for your interest in our services. 
                            We are excited about the possibility of tailoring our services to suit your needs. 
                            We encourage you to get in touch with us. 
                            </p>
                            <div className="mt-5 text-center text-lg-left">
                                <a href="#form-contact" data-scroll-to="" className="btn btn-white btn-lg btn-icon">
                                    <span className="btn-inner--icon">
                                        {/* Insert your SVG icon here */}
                                    </span>
                                    <span className="btn btn-outline-light">Write a message</span>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <hr/>

            <div className="container">
                <div className="row">
                <div className="col-lg-4 mb-4">
                    <div className="card text-center hover-translate-y-n10 hover-shadow-lg">
                    <div className="px-5 pb-5 pt-5">
                        <div className="py-4">
                        <div className="icon text-warning icon-sm mx-auto">
                            <img src={SalesImage} alt="sales support icon"/>
                        </div>
                        </div>
                        <h5>Sales Support</h5>
                        <p className="mt-2 mb-0 text-start">Our dedicated Sales Support team is committed to assisting you with all your product inquiries and project needs.</p>
                        <div className="mt-4">
                        <a href="/#top" className="link-stc-styled">sales@kirkpatrickcore.com</a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card text-center hover-translate-y-n10 hover-shadow-lg">
                    <div className="px-5 pb-5 pt-5">
                        <div className="py-4">
                        <div className="icon text-warning icon-sm mx-auto">
                            <img src={TechSupportImage} alt="tech support icon" />
                        </div>
                        </div>
                        <h5>Technical Support</h5>
                        <p className="mt-2 mb-0 text-start">Encounter an issue or need technical assistance? Our dedicated support team is on-call to help solve your problems.</p>
                        <div className="mt-4">
                        <a href="/#top" className="link-stc-styled">support@kirkpatrickcore.com</a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card text-center hover-translate-y-n10 hover-shadow-lg">
                    <div className="px-5 pb-5 pt-5">
                        <div className="py-4">
                        <div className="icon text-warning icon-sm mx-auto">
                            <img src={CareerSupportImage} alt="carrer support icon"/>
                        </div>
                        </div>
                        <h5>Career Support</h5>
                        <p className="mt-2 mb-0 text-start">Having trouble submitting submitting a job application or just need clarification? Connect with our support team today.</p>
                        <div className="mt-4">
                        <a href="/#top" className="link-stc-styled">careers@kirkpatrickcore.com</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <hr/>

            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-5 text-start">
                        <h3>P.O. Box #XXXX<br/>Athens, GA 30606</h3>
                        <p className="lead my-4">
                            E: <a href="/#top" className="link-stc-styled">support@kirkpatrickcore.com</a><br/>
                            T: (508) 496-3772
                        </p>
                        <p className="text-start">
                            Want to share any feedback with us, report a technical issue or just ask us a question? Feel free to contact us and we will get back to you shortly.
                        </p>
                    </div>
                    <div className="col-lg-6">
                    <MapContainer 
                        style={{ height: "600px", position: "relative", overflow: "hidden", borderRadius: "6%", boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)" }}
                        zoomControl={false}
                        touchZoom={false}
                        scrollWheelZoom={false}
                        doubleClickZoom={false}
                    >
                        <SetViewOnClick coords={position} />
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MyMarker position={position} icon={flagIcon}>
                            <Popup>
                                KirkpatrickCORE<br />Headquarters.
                            </Popup>
                        </MyMarker>
                    </MapContainer>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="container position-relative zindex-100" id="sct-form-contact">
                <div className="row justify-content-center mb-5">
                    <div className="col-lg-6 text-center">
                        <h3>Contact us</h3>
                        <p className="lh-190">If there's something we can help you with, just let us know. 
                        We'll be more than happy to offer you our help. 
                        Our support team is available 24/7 for any issues you may have.</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <form id="form-contact" onSubmit={(e) => {
                            e.preventDefault();
                            axios.post('/api/submit-contact', {
                                name: name,
                                email: email,
                                phone: phone,
                                text: message
                            })
                            .then(function (response) {
                                console.log(response);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                            setName("");
                            setEmail("");
                            setPhone("");
                            setMessage("");
                        }}>
                            <div className="form-group">
                                <input className="form-control form-control-lg" type="text" placeholder="Your name" required 
                                value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <input className="form-control form-control-lg" type="email" placeholder="email@example.com" required 
                                value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <input className="form-control form-control-lg" type="text" placeholder="(745)-234-567" required 
                                value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control form-control-lg" data-toggle="autosize" placeholder="Tell us a few words ..." rows={3} required 
                                value={message} onChange={(e) => setMessage(e.target.value)} 
                                style={{ overflow: "hidden", overflowWrap: "break-word", resize: "none", height: "106px" }}></textarea>
                            </div>
                            <div className="text-center">
                                <button type="reset" className="btn-reset contact-btn d-none"></button>
                                <button type="submit" className="btn contact-btn btn-block btn-lg mt-4">Send your message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default Contact;