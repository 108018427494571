import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/fonts.css';
import './AgileTrans.css';

const AgileTrans: React.FC = () => {
    return (
        <div className="container my-5">
            <div className="row mb-4">
                <div className="col">
                    <div className="agile-trans-background-image d-flex align-items-center justify-content-center rounded">
                        <h1 className="text text-start-center text text-start-white">Agile Transformation</h1>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row mt-5 mb-4">
                <div className="col-md-8 mx-auto mb-4">
                <h3 className="mb-4">Drive your business forward by embracing an Agile mindset.</h3>
                <p className="text text-start">
                    At KirkpatrickCORE, we champion the Agile mindset, a philosophy that emphasizes adaptability, 
                    iterative progress, and customer collaboration. 
                    Embracing this mindset brings with it a plethora of benefits such as improved software quality, 
                    faster delivery times, increased stakeholder satisfaction, 
                    and greater ability to handle changing priorities. 
                </p>
                <p className="text text-start">
                    In the realm of DevSecOps, Agile becomes the cornerstone for an environment where development, 
                    security, and operations teams work in harmony. It bolsters a culture of shared responsibility, 
                    fast feedback loops, and continual learning and improvement, 
                    leading to efficient and secure delivery of high-quality software.
                </p>
                <p className="text text-start">
                    In Machine Learning Operations (MLOps), Agile plays an even more critical role. 
                    It facilitates the effective collaboration between data scientists and operations personnel, 
                    fostering an environment for continuous integration, delivery, and model monitoring. 
                    It ensures models are efficiently deployed, managed, and updated in production, 
                    leading to reliable and faster business decisions.
                </p>
                <p className="text text-start">
                    In decision sciences, the Agile mindset can be a catalyst for unblocking stagnation. 
                    By emphasizing iterative progress and feedback, Agile enables teams to adapt their models and 
                    strategies swiftly in response to changes, ensuring business decisions are timely, accurate, and relevant. 
                    At KirkpatrickCORE, our objective is to help clients harness the power of Agile for transforming their business operations, 
                    making them more efficient, resilient, and primed for growth.
                </p>

                </div>
            </div>

        <hr/>

        <div className="row">
            <div className="col-md-4 mb-4">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Agile Scrum</h5>
                <p className="card-text">Agile Scrum promotes iterative progress, flexibility, and collaboration. With regular sprints and stand-ups, teams have increased visibility and can adapt quickly to changes.</p>
                </div>
            </div>
            </div>
            <div className="col-md-4 mb-4">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">SAFe Framework</h5>
                <p className="card-text">The Scaled Agile Framework (SAFe) is an enterprise-scale development framework, allowing teams to deliver products and services in short, high-value increments. It promotes alignment, collaboration, and delivery across multiple Agile teams.</p>
                </div>
            </div>
            </div>
            <div className="col-md-4 mb-4">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">CRISP-DM with Agile Scrum</h5>
                <p className="card-text">Combine the power of CRISP-DM for data science and machine learning with the flexibility and adaptability of Agile Scrum. This combo allows data science teams to prioritize tasks, adapt to changes, and deliver value quickly and consistently.</p>
                </div>
            </div>
            </div>
        </div>

        <hr/>

    </div>
    );
};

export default AgileTrans;