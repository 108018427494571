import React from 'react';
import './TriColumn.css';
import '../styles/fonts.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';
import CustomizationImage from '../assets/images/customization-header-3.png';
import ModernizationImage from '../assets/images/modernization-header-4.png';
import IntegrationImage from '../assets/images/integration-header-2.png';

const TriColumn: React.FC = () => {
    return (
        <div className="tricolumn container t-top">
            <div className="row">
                <div className="col-lg-4">
                <img
                    className="bd-placeholder-img rounded"
                    width="140"
                    height="140"
                    src={CustomizationImage}
                    aria-label="Cumstomization"
                    alt="Customization"
                />
                    <h2 className="fw-normal">Customization</h2>
                    <p className="text-start">Delivering tailored artificial intelligence and machine learning services, 
                        we employ innovative techniques and advanced algorithms to enhance and optimize your unique operations.</p>
                    <p>
                        <Link className="btn tri-col-btn" to="/ai-ml" onClick={scrollToTop}>View details &raquo;</Link>
                    </p>
                </div>
                <div className="col-lg-4">
                <img
                    className="bd-placeholder-img rounded"
                    width="140"
                    height="140"
                    src={ModernizationImage}
                    aria-label="Modernization"
                    alt="Modernization"
                />
                    <h2 className="fw-normal">Modernization</h2>
                    <p className="text-start">Navigate the digital landscape smoothly with our technology modernization services. 
                        From initial assessment to implementation, we future-proof your technology infrastructure with AI and ML.</p>
                    <p>
                        <Link className="btn tri-col-btn" to="/technology-modernization" onClick={scrollToTop}>View details &raquo;</Link>
                    </p>
                </div>
                <div className="col-lg-4">
                <img
                    className="bd-placeholder-img rounded"
                    width="140"
                    height="140"
                    src={IntegrationImage}
                    aria-label="Integration"
                    alt="Integration"
                />
                    <h2 className="fw-normal">Integration</h2>
                    <p className="text-start">We ensure secure, efficient integration of AI and ML into existing systems, 
                        aligning with federal standards to deliver value while prioritizing data security, protection and privacy.</p>
                    <p>
                        <Link className="btn tri-col-btn" to="/managed-devsecops" onClick={scrollToTop}>View details &raquo;</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TriColumn;